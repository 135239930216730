<template>
  <footer id="footer">
    <b-container>
      <b-row class="align-items-center">
        <b-col md="7" lg="4">
          <div class="box-footer-logo">
            <img src="~@/assets/images/logo.png" alt="" />
            <p>
              AIHA TECH is a pioneer in applying AI technologies in the fields of technology,
              finance, education, game... <br />
              AIHA is the latest generation automatic trading robot in the world owned by AIHA Tech
              Corporation in the US.
            </p>
          </div>
        </b-col>
        <b-col md="5" lg="4" class="box-social-content">
          <div class="box-footer-social">
            <div class="social-item">
              <img src="~@/assets/images/fb.png" alt="" />
              <a href="https://facebook.com/aihatech/" target="_blank"> <p>Facebook</p></a>
            </div>
            <div class="social-menu">
              <a href="#aboutUs">
                <p>About us</p>
              </a>
            </div>
          </div>
          <div class="box-footer-social">
            <div class="social-item">
              <img src="~@/assets/images/twit.png" alt="" />
              <a href="https://twitter.com/aihatech" target="_blank">
                <p>Twitter</p>
              </a>
            </div>
            <div class="social-menu">
              <a href="#advantage">
                <p>Advantage</p>
              </a>
            </div>
          </div>
          <div class="box-footer-social">
            <div class="social-item">
              <img src="~@/assets/images/tele.png" alt="" />
              <a href="https://t.me/aihatech" target="_blank">
                <p>Telegram Group</p>
              </a>
            </div>
            <div class="social-menu">
              <a href="#pricing">
                <p>Pricing</p>
              </a>
            </div>
          </div>
          <div class="box-footer-social">
            <div class="social-item">
              <img src="~@/assets/images/ins.png" alt="" />
              <a href="https://www.linkedin.com/in/aiha-bot-b02176237/" target="_blank">
                <p>LinkedIn</p>
              </a>
            </div>
            <div class="social-menu">
              <p>Team</p>
            </div>
          </div>
          <div class="box-footer-social">
            <div class="social-item">
              <img src="~@/assets/images/red.png" alt="" />
              <a href="https://www.reddit.com/user/Aiharobot" target="_blank">
                <p>Reddit</p>
              </a>
            </div>
            <div class="social-menu">
              <a href="#contact">
                <p>Contact</p>
              </a>
            </div>
          </div>
          <div class="box-footer-social">
            <div class="social-item">
              <img src="~@/assets/images/tele.png" alt="" />
              <a href="https://t.me/aihachannel" target="_blank">
                <p>Telegram Channel</p>
              </a>
            </div>
            <div class="social-menu">
              <a href="">
                <p></p>
              </a>
            </div>
          </div>
        </b-col>
        <b-col lg="4">
          <div class="box-footer-blog">
            <h3>KNOWLEDGE BLOG</h3>
            <div class="box-item">
              <p>WHAT IS TRADING BOT ?</p>
            </div>
            <div class="box-item">
              <p>THE ADVANTAGES OF TRADING BOT</p>
            </div>
            <div class="box-item">
              <p>"TIPS" TO USE TRADING BOT EFFECTIVELY</p>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <a href="#"><button class="btn-top">Top</button></a>
  </footer>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
  watch: {},
  created() {},
};
</script>

<style lang="scss">
footer#footer {
  width: 100%;
  height: 100%;
  color: #ffffff;
  padding: 50px 0px;
  box-shadow: -1px -1px 20px #000000;
  position: relative;
  height: 100%;
  background-color: #000000;
  background-image: url('~@/assets/images/bg-footer.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  .box-footer-logo {
    height: 100%;
    position: relative;
    z-index: 10;
  }
  .box-social-content {
    margin-top: 80px;
    position: relative;
    z-index: 10;
    @media (max-width: 991px) {
      margin-top: 0px;
    }
    @media (max-width: 767px) {
      margin-top: 50px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    .box-footer-social {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      margin-bottom: 20px;
      position: relative;
      z-index: 10;
      .social-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media (max-width: 767px) {
          width: 250px;
          justify-content: flex-start;
        }
        @media (max-width: 375px) {
          width: 200px;
        }
        img {
          margin-right: 10px;
        }
        a {
          color: #fff;
          p {
            margin-bottom: 0px;
          }
        }
      }
      .social-menu {
        a {
          color: #ffffff;
          p {
            margin-bottom: 0px;
          }
        }
      }
    }
  }
  .box-footer-blog {
    text-align: center;
    position: relative;
    z-index: 10;
    @media (max-width: 991px) {
      margin-top: 50px;
    }
    h3 {
      margin-bottom: 40px;
    }
    .box-item {
      width: 100%;
      height: 70px;
      padding: 0px 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      background: linear-gradient(135deg, rgba(7, 63, 95, 0.28) 0%, rgba(7, 67, 101, 0.46) 100%);
      border: 1px solid #093a55;
      border-radius: 10px;
      color: #fff;
      margin-bottom: 30px;
      p {
        font-weight: bold;
        margin-bottom: 0px;
      }
    }
  }
  .btn-top {
    position: absolute;
    bottom: 20px;
    right: 20px;
    background: none;
    border: 1px solid #00fff8;
    color: #00fff8;
    padding: 10px;
    border-radius: 50%;
    z-index: 10;
  }
}
</style>
