<template>
  <header id="header">
    <div class="left-header">
      <div class="logo">
        <img src="~@/assets/images/logo.png" alt="" />
      </div>
      <div class="menu" v-if="showMenu && width > 1024">
        <a
          href="#"
          class="menu-item"
          :class="activeMenu === 1 ? 'active' : ''"
          @click="activeMenu = 1"
        >
          <span
            >Home
            <div class="line-bottom"></div
          ></span>
        </a>
        <a
          href="#aboutUs"
          class="menu-item"
          :class="activeMenu === 2 ? 'active' : ''"
          @click="activeMenu = 2"
        >
          <span
            >About us
            <div class="line-bottom"></div
          ></span>
        </a>
        <a
          href="#advantage"
          class="menu-item"
          :class="activeMenu === 3 ? 'active' : ''"
          @click="activeMenu = 3"
        >
          <span
            >Advantage
            <div class="line-bottom"></div
          ></span>
        </a>
        <a
          href="#pricing"
          class="menu-item"
          :class="activeMenu === 4 ? 'active' : ''"
          @click="activeMenu = 4"
        >
          <span
            >Pricing
            <div class="line-bottom"></div
          ></span>
        </a>
        <a
          href="#team"
          class="menu-item"
          :class="activeMenu === 5 ? 'active' : ''"
          @click="activeMenu = 5"
        >
          <span
            >Team
            <div class="line-bottom"></div
          ></span>
        </a>
        <a
          href="#blog"
          class="menu-item"
          :class="activeMenu === 6 ? 'active' : ''"
          @click="activeMenu = 6"
        >
          <span
            >Blog
            <div class="line-bottom"></div
          ></span>
        </a>
        <a
          href="#contact"
          class="menu-item"
          :class="activeMenu === 7 ? 'active' : ''"
          @click="activeMenu = 7"
        >
          <span
            >Contact
            <div class="line-bottom"></div
          ></span>
        </a>
      </div>
      <div class="menu" v-if="showMenu && width <= 1024">
        <a
          href="#"
          class="menu-item"
          :class="activeMenu === 1 ? 'active' : ''"
          @click="
            activeMenu = 1;
            showMenu = false;
          "
        >
          <span
            >Home
            <div class="line-bottom"></div
          ></span>
        </a>
        <a
          href="#aboutUs"
          class="menu-item"
          :class="activeMenu === 2 ? 'active' : ''"
          @click="
            activeMenu = 2;
            showMenu = false;
          "
        >
          <span
            >About us
            <div class="line-bottom"></div
          ></span>
        </a>
        <a
          href="#advantage"
          class="menu-item"
          :class="activeMenu === 3 ? 'active' : ''"
          @click="
            activeMenu = 3;
            showMenu = false;
          "
        >
          <span
            >Advantage
            <div class="line-bottom"></div
          ></span>
        </a>
        <a
          href="#pricing"
          class="menu-item"
          :class="activeMenu === 4 ? 'active' : ''"
          @click="
            activeMenu = 4;
            showMenu = false;
          "
        >
          <span
            >Pricing
            <div class="line-bottom"></div
          ></span>
        </a>
        <a
          href="#team"
          class="menu-item"
          :class="activeMenu === 5 ? 'active' : ''"
          @click="
            activeMenu = 5;
            showMenu = false;
          "
        >
          <span
            >Team
            <div class="line-bottom"></div
          ></span>
        </a>
        <a
          href="#blog"
          class="menu-item"
          :class="activeMenu === 6 ? 'active' : ''"
          @click="
            activeMenu = 6;
            showMenu = false;
          "
        >
          <span
            >Blog
            <div class="line-bottom"></div
          ></span>
        </a>
        <a
          href="#contact"
          class="menu-item"
          :class="activeMenu === 7 ? 'active' : ''"
          @click="
            activeMenu = 7;
            showMenu = false;
          "
        >
          <span
            >Contact
            <div class="line-bottom"></div
          ></span>
        </a>
      </div>
      <div class="icon-menu-mobile" v-if="width <= 1024" @click="showMenu = !showMenu">
        <img src="~@/assets/images/menu.png" alt="" />
      </div>
    </div>
  </header>
</template>

<script>
export default {
  data() {
    return {
      width: 1920,
      activeMenu: 1,
      showMenu: true,
    };
  },
  methods: {
    handleResize() {
      this.width = window.innerWidth;
      if (window.innerWidth <= 1024) {
        this.showMenu = false;
      } else {
        this.showMenu = true;
      }
    },
    changeLanguage(lang) {
      this.currentLanguage = lang;
      this.$i18n.locale = lang;
    },
  },
  async created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  async mounted() {
    if (window.innerWidth <= 1024) {
      this.showMenu = false;
    } else {
      this.showMenu = true;
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  watch: {
    $route(to, from) {
      if (to.name !== from.name) {
        if (this.width <= 1024) {
          this.showMenu = false;
        }
      }
    },
  },
};
</script>

<style lang="scss">
header#header {
  background-color: #000000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  max-width: 100vw;
  height: 100px;
  overflow: visible;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  z-index: 1030;
  @media (max-width: 768px) {
    height: 80px;
    padding: 0px;
  }
  @media (min-width: 1440px) {
    padding: 0 40px;
  }

  .left-header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
    @media (max-width: 1024px) {
      justify-content: flex-end;
    }
    .logo {
      padding: 10px 20px;
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 0px;
      @media (max-width: 575px) {
        height: 80px;
      }
      img {
        height: 100%;
        width: auto;
        filter: drop-shadow(5px 5px 5px rgba($color: #000000, $alpha: 0.5));
      }
    }
    .menu {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      height: 100px;
      margin-left: 20px;
      @media (max-width: 1099px) {
        margin-left: 5px;
      }
      .menu-item {
        text-decoration: none;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 15px;
        position: relative;
        &:hover {
          background: rgba($color: #000000, $alpha: 0.3);
          span {
            color: red;
          }
        }
        &.active {
          background: rgba($color: #000000, $alpha: 0.3);
          span {
            position: relative;
            .line-bottom {
              position: absolute;
              bottom: -10px;
              width: 80%;
              height: 2px;
              background-color: red;
              @media (max-width: 1024px) {
                width: 100%;
              }
            }
          }
        }
        &.menu-lang {
          img {
            width: 40px;
            height: auto;
          }
        }
        span {
          font-size: 20px;
          color: #fff;
          @media (max-width: 1599px) {
            font-size: 18px;
          }
          @media (max-width: 1299px) {
            font-size: 17px;
          }
          @media (max-width: 1222px) {
            font-size: 16px;
          }
        }
        @media (max-width: 1599px) {
          min-width: inherit;
          width: auto;
        }
        @media (max-width: 1299px) {
          min-width: fit-content;
          &:nth-child(1) {
            min-width: fit-content;
          }
        }
        @media (max-width: 1099px) {
          // min-width: 8vw;
          // &:nth-child(1) {
          //   min-width: 8vw;
          // }
          padding: 0 10px;
        }
      }
      .menu-item2 {
        .nav-link {
          width: 100%;
          min-width: 250px;
          &:after {
            top: 2px;
            border-top: 0.5em solid #333;
            border-right: 0.5em solid transparent;
            border-bottom: 0;
          }
          span {
            font-size: 15px;
            color: #333 !important;
          }
          &:hover {
            background-color: rgba(0, 0, 0, 0.8);
            font-weight: 600;
            color: #4effff !important;
            &:after {
              background: none;
            }
            span {
              color: #4effff !important;
            }
          }
          @media (max-width: 1024px) {
            display: flex;
            justify-content: center;
          }
        }
        .dropdown-menu {
          @media (max-width: 1024px) {
            top: -180px !important;
            overflow: auto;
          }
        }
      }

      .nav-link {
        display: inline-flex;
        align-items: center;
        &:focus-visible {
          outline: 0;
        }
        &:hover {
          &:after {
            border-top: 0.7em solid #4effff;
            border-right: 0.7em solid transparent;
            margin-left: 10px;
          }
        }
        &:after {
          border-top: 0.7em solid #fff;
          border-right: 0.7em solid transparent;
          border-left: 0.3em solid transparent;
          margin-left: 10px;
        }
      }
      .dropdown-menu {
        top: 20px !important;
        left: -58px !important;
        min-width: 100%;
        padding: 15px 0;
        border-radius: 5px;
        border: 0;
        background-color: #fff;
        box-shadow: 2px 2px 6px 1px #0000002b;
        @media (max-width: 1024px) {
          left: 2px !important;
          min-width: 98%;
          text-align: center;
        }
        &.custom-dropdow-mobile {
          top: 50px !important;
          right: 0px !important;
          width: 80px;
          padding: 0px 0;
          border-radius: 5px;
          border: 0;
          background-color: #0f233b;
          box-shadow: 2px 2px 6px 1px #0000002b;
          text-align: center;
          min-width: inherit;
          @media (max-width: 1024px) {
            top: -5px !important;
            left: -10px !important;
            min-width: inherit;
            text-align: center;
            background-color: #22364f;
          }
          .dropdown-item {
            padding: 7px 10px;
            width: 100%;
            min-width: inherit !important;
            font-size: 15px;
            color: #333;
          }
        }
        .dropdown-item {
          padding: 10px 15px;
          min-width: 250px;
          font-size: 15px;
          color: #333;
        }
        .dropdown-item:hover,
        .dropdown-item:focus,
        .dropdown-item.active,
        .dropdown-item:active {
          background: none;
          background-color: rgba(0, 0, 0, 0.8);
          font-weight: 600;
          color: #4effff;
        }
      }
    }
    .icon-menu-mobile {
      padding: 10px 20px;
      height: 80px;
      @media (max-width: 767px) {
        height: 60px;
      }
      img {
        height: 100%;
        width: auto;
        filter: drop-shadow(5px 5px 5px rgba($color: #000000, $alpha: 0.5));
      }
    }

    @media (max-width: 1024px) {
      .menu {
        position: fixed;
        top: 100px;
        right: 0;
        bottom: 0;
        flex-direction: column;
        width: 320px;
        background: #0f233b;
        height: calc(100vh - 100px);
        justify-content: flex-start;
        padding: 0px 0;
        .menu-item {
          width: 100%;
          height: 80px;
        }
      }
    }
    @media (max-width: 767px) {
      .menu {
        top: 80px;
        .menu-item {
          width: 100%;
          height: 60px;
        }
      }
    }
    @media (max-width: 575px) {
      .menu {
        width: 100%;
        height: 100vh;
      }
    }
  }
}
</style>
